<template>
  <section class="gerenciamento-consultas-bureau pr-4">
    <div class="m-1 mr-4 px-0">
      <breadcrumb
        title="Gerenciamento de consultas"
        actualPage="Consultas bureau"
      />
    </div>

    <filtro ref="filtro" :isFilter="filtro" :bureauGerenciamentoConsultas="true">
      <div class="pt-2 pb-6 px-4">
        <vs-row class="pt-0">
          <vs-col class="container-element pt-6" vs-lg="6" vs-sm="12">
            <p class="label-content label">CPF / CNPJ</p>
            <vs-input
              size="large"
              class="w-full"
              label=""
              v-model="filterValue.cpfCnpj"
              v-mask="['###.###.###-##', '##.###.###/####-##']"
            />
          </vs-col>

          <vs-col class="container-element pt-6" vs-lg="6" vs-sm="12">
            <p class="label-content label">Tipo da consulta</p>
            <el-select
              v-model="filterValue.tipoConsulta"
              placeholder="Selecionar"
              class="gerenciamento-consultas-bureau-filtro-select"
            >
              <el-option
                v-for="(consulta, index) in tiposDeConsulta"
                :key="index"
                :label="consulta.Nome"
                :value="consulta.Id"
              >
              </el-option>
            </el-select>
          </vs-col>
        </vs-row>

        <vs-row class="gerenciamento-consultas-bureau-filtro-select">
          <vs-col class="container-element pt-6" vs-lg="6" vs-sm="12">
            <p class="label-content label">Data inicial</p>
            <datepicker
              :language="language"
              format="dd/MM/yyyy"
              v-model="filterValue.startDate"
            ></datepicker>
          </vs-col>

          <vs-col class="container-element pt-6" vs-lg="6" vs-sm="12">
            <p class="label-content label">Data final</p>
            <datepicker
              :language="language"
              format="dd/MM/yyyy"
              v-model="filterValue.endDate"
            ></datepicker>
          </vs-col>
        </vs-row>

        <hr class="mt-6 opacity-25" />

        <vs-row class="gerenciamento-consultas-bureau-filtro-select">
          <vs-col class="container-element" vs-lg="6" vs-sm="12">
            <vs-checkbox v-model="apenasLogsErro"
              >Consultar apenas erros
            </vs-checkbox>
          </vs-col>
        </vs-row>

        <div class="flex flex-row-reverse mt-4">
          <vs-button
            @click.native="filtrarErrosConsulta(true)"
            color="primary"
            type="filled"
            class="ml-4"
            >Aplicar filtro</vs-button
          >
          <vs-button
            color="primary"
            type="filled"
            icon="clear"
            @click.native="limparFiltro()"
            >Limpar filtro</vs-button
          >
        </div>
      </div>
    </filtro>

    <template v-if="listaConsultas.length > 0">
      <vs-table
        class="table-list"
        :data="listaConsultas || []"
        :hoverFlat="true"
        noDataText="Nenhum erro encontrado"
        :max-items="rowsPerPage"
      >
        <template slot="thead">
          <vs-th class="text-center">Data/Hora</vs-th>
          <vs-th class="text-center">Id da consulta</vs-th>
          <vs-th class="text-center">CNPJ</vs-th>
          <vs-th class="text-center">Bureau usado</vs-th>
          <vs-th class="text-center">Mensagem</vs-th>
          <vs-th class="text-center"></vs-th>
          <vs-th class="text-center"></vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="tr.DataConsulta" class="text-center">
              <span class="font-semibold content">
                {{ formatarData(tr.DataConsulta) }}
              </span>
            </vs-td>

            <vs-td :data="tr.TipoConsulta" class="text-center">
              <span class="font-semibold content">
                {{ tr.TipoConsulta }}
              </span>
            </vs-td>

            <vs-td :data="tr.CpfCnpj" class="text-center">
              <span class="font-semibold content">
                {{ formatarCpfCnpj(tr.CpfCnpj) }}
              </span>
            </vs-td>

            <vs-td :data="tr.Bureau" class="text-center">
              <span class="font-semibold content">
                {{ tr.Bureau }}
              </span>
            </vs-td>

            <vs-td :data="tr.Error" class="text-center">
              <span class="font-semibold content">
                {{ tr.Mensagem }}
              </span>
            </vs-td>

            <vs-td class="text-center">
              <span class="space"> </span>
            </vs-td>

            <vs-td :data="tr.Error" class="text-center">
              <div class="copy-icon" @click="copiarErro(tr.Error)">
                <i class="onpoint-copy"/>
                <span>Copiar erro</span>
              </div>

              <div class="copy-icon mt-5" @click="copiarDadosOriginais(tr.BureauDados)">
                <i class="onpoint-copy"/>
                <span>Dados originais</span>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-pagination
        class="mt-5"
        :total="numberOfPages"
        v-model="currentPage"
        :max="9"
      ></vs-pagination>
    </template>
    <template v-else>
      <div class="flex flex-col w-full md:w-1/2 mx-auto text-center mt-32">
        <i class="onpoint-text-file"/>
        <p>Nenhuma consulta encontrada</p>
      </div>
    </template>
  </section>
</template>

<script>
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb";
import Filtro from "@/components/Filtro/Filtro.vue";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import { mask } from "vue-the-mask";

import PerfilHelpers from "@/helpers/PerfilHelpers";
import axiosInstance from "@/axios";
import moment from "moment";
import * as Utils from "@/utils/utils";

export default {
  name: "gerenciamento-consultas-bureau",
  directives: { mask },
  components: {
    Breadcrumb,
    Filtro,
    Datepicker
  },
  data() {
    return {
      perfilHelpers: PerfilHelpers,
      listaConsultas: [],
      tiposDeConsulta: [],
      totalItems: 0,
      currentPage: 1,
      rowsPerPage: 7,
      numberOfPages: 0,
      filtro: false,
      language: ptBR,
      filterValue: {
        seguradora: "",
        bureau: "",
        tipoConsulta: "",
        cpfCnpj: "",
        startDate: null,
        endDate: null
      },
      seguradoraId: null,
      apenasLogsErro: false
    };
  },
  watch: {
    currentPage(newVal, oldVal) {
      this.filtrarErrosConsulta();
    }
  },
  computed: {},
  methods: {
    async listarConsultas(
      cpfCnpj = "",
      tipoConsulta = "",
      dataConsultaInicial = "",
      dataConsultaFinal = "",
      OnlyLogError = null
    ) {
      let dataInicial = dataConsultaInicial
        ? Utils.dateToDDMMYYYY(dataConsultaInicial)
        : "";
      let dataFinal = dataConsultaFinal
        ? Utils.dateToDDMMYYYY(dataConsultaFinal)
        : "";
      let limitResultSizeRows = 50;

      await this.$onpoint.loading(async () => {
        return await axiosInstance
          .get(
            `api/Parametros/ObterBureauDadosLog?OnlyLogError=${OnlyLogError}&CpfCnpj=${cpfCnpj}&TipoConsulta=${tipoConsulta}&DataConsultaInicial=${dataInicial}&DataConsultaFinal=${dataFinal}&SeguradoraId=${this.seguradoraId}&currentPage=${this.currentPage}&rowsPerPage=${this.rowsPerPage}&LimitResultSizeRows=${limitResultSizeRows}`
          )
          .then(response => response.data.Response)
          .then(response => {
            this.totalItems = response.NumberOfRows;
            this.numberOfPages = response.NumberOfPages;
            this.listaConsultas = response.Data.map(
              item => item.BureauDadosLog
            );
          });
      });
    },

    async filtrarErrosConsulta(setCurrentPage) {

      this.filtro = !this.filtro;
      if (setCurrentPage) this.currentPage = 1;

      this.listarConsultas(
        Utils.removeSpecialChars(this.filterValue.cpfCnpj),
        this.filterValue.tipoConsulta,
        this.filterValue.startDate,
        this.filterValue.endDate,
        this.apenasLogsErro ? this.apenasLogsErro : null
      );
    },

    async pegarSelecaoTiposConsulta() {
      this.tiposDeConsulta = [];

      return await axiosInstance
        .get(`api/Parametros/ObterGetEnumDescriptionsBureauDados`)
        .then(response => response.data.Response)
        .then(response => (this.tiposDeConsulta = response));
    },

    limparFiltro() {
      this.filterValue = Object.fromEntries(
        Object.keys(this.filterValue).map(chave => [chave, ""])
      );
      this.currentPage = 1;
      this.apenasLogsErro = false;
      this.filtrarErrosConsulta();
    },

    formatarData(date) {
      if (date instanceof Date) {
        date = date.setHours(0, 0, 0, 0);
      }
      return moment(date).format("DD/MM/YYYY - hh:mm");
    },

    converterStringsParaObjetos(data) {
      try {
        const objetoErro = JSON.parse(data);
        return objetoErro;
      } catch (err) {
        console.log(err);
      }
    },

    formatarCpfCnpj(cpfCnpj) {
      return Utils.maskDocument(cpfCnpj);
    },

    copiarErro(error) {
      try {
        let linkAssinatura = error;
        if (!linkAssinatura) throw new Error("Não foi possível copiar o erro");
        this.copyToClipboard(linkAssinatura);
      } catch (error) {
        this.$notify({
          title: "Erro",
          message: "Não foi possível copiar o erro",
          type: "error",
          position: "bottom-right"
        });
      }
    },

    copiarDadosOriginais(dadosOriginais) {
      try {
        let linkAssinatura = dadosOriginais.Dados ? dadosOriginais.Dados : "";
        if (!linkAssinatura) throw new Error("Dados originais não encontrados");
        this.copyToClipboard(linkAssinatura);
      } catch (error) {
        this.$notify({
          title: "Erro",
          message: "Não foi possível copiar os dados originais",
          type: "error",
          position: "bottom-right"
        });
      }
    },

    async copyToClipboard(myLink) {
      try {
        await navigator.clipboard.writeText(myLink);
        this.$notify({
          title: "Sucesso",
          message: "Dados copiados com sucesso!",
          type: "success",
          position: "bottom-right"
        });
      } catch ($e) {}
    },

    async carregarListaDeConsultas() {
      await this.listarConsultas();
    }
  },
  async created() {
    this.$appInsights.trackPageView({
      name: "gerenciamento-consultas-bureau",
      url: window.location.href
    });

    let portalConfig = JSON.parse(localStorage.getItem("lsApplicationConfig"));
    this.seguradoraId = portalConfig.CompanyId;

    await this.$onpoint.loading(async () => {
      await this.carregarListaDeConsultas();
      await this.pegarSelecaoTiposConsulta();
    });
  }
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/mixins";
.con-vs-popup {
  z-index: 52000;
}

.table-list {
  ::v-deep .vs-table--thead {
    tr {
      background: transparent !important;
    }
  }

  ::v-deep .vs-table--td {
    padding: 25px 15px !important;
    margin-bottom: 1em;

    .content {
      line-height: 40px;
    }

    .space {
      display: inline-block;
      width: 120px !important;
    }
  }

  ::v-deep .vs-table--search {
    max-width: initial;
    margin-bottom: 20px;
    i {
      font-size: 2em;
      margin-top: 30px;

      @include md {
        margin-top: -24px;
      }
    }

    input {
      padding-left: 40px !important;
      font-size: 1.1em !important;
      width: 300px;
      border: 0 !important;
      height: 47px;
      margin-top: 30px;

      @include md {
        margin-top: -24px;
      }
    }
  }

  ::v-deep .vs-table-text {
    justify-content: center;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }

  ::v-deep .vs-table--tbody-table .tr-values:hover {
    opacity: 1 !important;
  }
}

.copy-icon {
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 18px !important;
  padding: 8px 10px;
  border: 1px solid #d1d5db;
  border-radius: 50px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.07);
  transition: background-color 0.4s ease;
  user-select: none;
  cursor: pointer;

  &-text {
    font-weight: 500;
    font-size: 14px;
    margin-left: 8px;
  }
}

.copy-icon:hover {
  background-color: rgba(209, 213, 219, 0.4);
}
</style>
