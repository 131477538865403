var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "gerenciamento-consultas-bureau pr-4" },
    [
      _c(
        "div",
        { staticClass: "m-1 mr-4 px-0" },
        [
          _c("breadcrumb", {
            attrs: {
              title: "Gerenciamento de consultas",
              actualPage: "Consultas bureau"
            }
          })
        ],
        1
      ),
      _c(
        "filtro",
        {
          ref: "filtro",
          attrs: { isFilter: _vm.filtro, bureauGerenciamentoConsultas: true }
        },
        [
          _c(
            "div",
            { staticClass: "pt-2 pb-6 px-4" },
            [
              _c(
                "vs-row",
                { staticClass: "pt-0" },
                [
                  _c(
                    "vs-col",
                    {
                      staticClass: "container-element pt-6",
                      attrs: { "vs-lg": "6", "vs-sm": "12" }
                    },
                    [
                      _c("p", { staticClass: "label-content label" }, [
                        _vm._v("CPF / CNPJ")
                      ]),
                      _c("vs-input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: ["###.###.###-##", "##.###.###/####-##"],
                            expression:
                              "['###.###.###-##', '##.###.###/####-##']"
                          }
                        ],
                        staticClass: "w-full",
                        attrs: { size: "large", label: "" },
                        model: {
                          value: _vm.filterValue.cpfCnpj,
                          callback: function($$v) {
                            _vm.$set(_vm.filterValue, "cpfCnpj", $$v)
                          },
                          expression: "filterValue.cpfCnpj"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "vs-col",
                    {
                      staticClass: "container-element pt-6",
                      attrs: { "vs-lg": "6", "vs-sm": "12" }
                    },
                    [
                      _c("p", { staticClass: "label-content label" }, [
                        _vm._v("Tipo da consulta")
                      ]),
                      _c(
                        "el-select",
                        {
                          staticClass:
                            "gerenciamento-consultas-bureau-filtro-select",
                          attrs: { placeholder: "Selecionar" },
                          model: {
                            value: _vm.filterValue.tipoConsulta,
                            callback: function($$v) {
                              _vm.$set(_vm.filterValue, "tipoConsulta", $$v)
                            },
                            expression: "filterValue.tipoConsulta"
                          }
                        },
                        _vm._l(_vm.tiposDeConsulta, function(consulta, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: consulta.Nome, value: consulta.Id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "vs-row",
                { staticClass: "gerenciamento-consultas-bureau-filtro-select" },
                [
                  _c(
                    "vs-col",
                    {
                      staticClass: "container-element pt-6",
                      attrs: { "vs-lg": "6", "vs-sm": "12" }
                    },
                    [
                      _c("p", { staticClass: "label-content label" }, [
                        _vm._v("Data inicial")
                      ]),
                      _c("datepicker", {
                        attrs: { language: _vm.language, format: "dd/MM/yyyy" },
                        model: {
                          value: _vm.filterValue.startDate,
                          callback: function($$v) {
                            _vm.$set(_vm.filterValue, "startDate", $$v)
                          },
                          expression: "filterValue.startDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "vs-col",
                    {
                      staticClass: "container-element pt-6",
                      attrs: { "vs-lg": "6", "vs-sm": "12" }
                    },
                    [
                      _c("p", { staticClass: "label-content label" }, [
                        _vm._v("Data final")
                      ]),
                      _c("datepicker", {
                        attrs: { language: _vm.language, format: "dd/MM/yyyy" },
                        model: {
                          value: _vm.filterValue.endDate,
                          callback: function($$v) {
                            _vm.$set(_vm.filterValue, "endDate", $$v)
                          },
                          expression: "filterValue.endDate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("hr", { staticClass: "mt-6 opacity-25" }),
              _c(
                "vs-row",
                { staticClass: "gerenciamento-consultas-bureau-filtro-select" },
                [
                  _c(
                    "vs-col",
                    {
                      staticClass: "container-element",
                      attrs: { "vs-lg": "6", "vs-sm": "12" }
                    },
                    [
                      _c(
                        "vs-checkbox",
                        {
                          model: {
                            value: _vm.apenasLogsErro,
                            callback: function($$v) {
                              _vm.apenasLogsErro = $$v
                            },
                            expression: "apenasLogsErro"
                          }
                        },
                        [_vm._v("Consultar apenas erros\n          ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex flex-row-reverse mt-4" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "ml-4",
                      attrs: { color: "primary", type: "filled" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.filtrarErrosConsulta(true)
                        }
                      }
                    },
                    [_vm._v("Aplicar filtro")]
                  ),
                  _c(
                    "vs-button",
                    {
                      attrs: {
                        color: "primary",
                        type: "filled",
                        icon: "clear"
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.limparFiltro()
                        }
                      }
                    },
                    [_vm._v("Limpar filtro")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm.listaConsultas.length > 0
        ? [
            _c(
              "vs-table",
              {
                staticClass: "table-list",
                attrs: {
                  data: _vm.listaConsultas || [],
                  hoverFlat: true,
                  noDataText: "Nenhum erro encontrado",
                  "max-items": _vm.rowsPerPage
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(ref) {
                        var data = ref.data
                        return _vm._l(data, function(tr, indextr) {
                          return _c(
                            "vs-tr",
                            { key: indextr },
                            [
                              _c(
                                "vs-td",
                                {
                                  staticClass: "text-center",
                                  attrs: { data: tr.DataConsulta }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-semibold content" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.formatarData(tr.DataConsulta)
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "vs-td",
                                {
                                  staticClass: "text-center",
                                  attrs: { data: tr.TipoConsulta }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-semibold content" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(tr.TipoConsulta) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "vs-td",
                                {
                                  staticClass: "text-center",
                                  attrs: { data: tr.CpfCnpj }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-semibold content" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.formatarCpfCnpj(tr.CpfCnpj)
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "vs-td",
                                {
                                  staticClass: "text-center",
                                  attrs: { data: tr.Bureau }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-semibold content" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(tr.Bureau) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "vs-td",
                                {
                                  staticClass: "text-center",
                                  attrs: { data: tr.Error }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "font-semibold content" },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(tr.Mensagem) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c("vs-td", { staticClass: "text-center" }, [
                                _c("span", { staticClass: "space" })
                              ]),
                              _c(
                                "vs-td",
                                {
                                  staticClass: "text-center",
                                  attrs: { data: tr.Error }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "copy-icon",
                                      on: {
                                        click: function($event) {
                                          return _vm.copiarErro(tr.Error)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", { staticClass: "onpoint-copy" }),
                                      _c("span", [_vm._v("Copiar erro")])
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "copy-icon mt-5",
                                      on: {
                                        click: function($event) {
                                          return _vm.copiarDadosOriginais(
                                            tr.BureauDados
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("i", { staticClass: "onpoint-copy" }),
                                      _c("span", [_vm._v("Dados originais")])
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        })
                      }
                    }
                  ],
                  null,
                  false,
                  359999465
                )
              },
              [
                _c(
                  "template",
                  { slot: "thead" },
                  [
                    _c("vs-th", { staticClass: "text-center" }, [
                      _vm._v("Data/Hora")
                    ]),
                    _c("vs-th", { staticClass: "text-center" }, [
                      _vm._v("Id da consulta")
                    ]),
                    _c("vs-th", { staticClass: "text-center" }, [
                      _vm._v("CNPJ")
                    ]),
                    _c("vs-th", { staticClass: "text-center" }, [
                      _vm._v("Bureau usado")
                    ]),
                    _c("vs-th", { staticClass: "text-center" }, [
                      _vm._v("Mensagem")
                    ]),
                    _c("vs-th", { staticClass: "text-center" }),
                    _c("vs-th", { staticClass: "text-center" })
                  ],
                  1
                )
              ],
              2
            ),
            _c("vs-pagination", {
              staticClass: "mt-5",
              attrs: { total: _vm.numberOfPages, max: 9 },
              model: {
                value: _vm.currentPage,
                callback: function($$v) {
                  _vm.currentPage = $$v
                },
                expression: "currentPage"
              }
            })
          ]
        : [_vm._m(0)]
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex flex-col w-full md:w-1/2 mx-auto text-center mt-32"
      },
      [
        _c("i", { staticClass: "onpoint-text-file" }),
        _c("p", [_vm._v("Nenhuma consulta encontrada")])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }